<template>
    <el-dialog
            title="账户编辑"
            :visible="dialogShow"
            append-to-body
            width="500px"
            :before-close="beforeHandle"
            class="mt4-edit-dialog"
    >
        <div class="content">
            <el-row class="el-row">
                <el-col :span="4">客户信息:</el-col>
                <el-col :span="20">
                    <div>{{userInfo.userName}}</div>
                    <div>{{userInfo.phone}}</div>
                    <div>{{userInfo.email}}</div>
                </el-col>
            </el-row>
            <el-row class="el-row">
                <el-col :span="4">MT4账户:</el-col>
                <el-col :span="20">
                    <div>{{userInfo.login}}</div>
                </el-col>
            </el-row>
            <el-form
                    ref="form"
                    :model="form"
                    size="small"
                    label-width="80px"
            >
                <el-form-item label="MT4分组">
                    <el-select
                            v-model="form.mtGroup"
                    >
                        <el-option-group
                                v-for="(group,name,index) in groupFilter"
                                :key="index"
                                :label="name"
                        >
                            <el-option
                                    v-for="item in group"
                                    :key="item.id"
                                    :label="item.groupName"
                                    :value="item.id">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>
                <el-form-item label="杠杆">
                    <el-select
                            class="select"
                            v-model="form.leverage"
                            placeholder="杆杠"
                    >
                        <el-option
                                v-for="item in leverList"
                                :key="item.lev"
                                :label="item.levName"
                                :value="item.lev"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="登录状态">
                <el-radio-group
                    class="select"
                    v-model="form.loginStatus"
                >
                  <el-radio
                      :label="item.key"
                      :key="item.key"
                      v-for="item in loginStatusOptions"
                  >{{item.display_name}}</el-radio>
                </el-radio-group>
              </el-form-item>
                <el-form-item label="交易状态">
                    <el-radio-group class="select"
                                    v-model="form.tradeStatus">
                        <el-radio
                                :label="item.key"
                                :key="item.key"
                                v-for="item in statusOptions"
                        >{{item.display_name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="发送报告">
                    <el-radio-group
                            class="select"
                            v-model="form.sendReport"
                    >
                        <el-radio
                                :label="item.key"
                                :key="item.key"
                                v-for="item in sendReportStatusOptions"
                        >{{item.display_name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="修改密码">
                    <el-radio-group
                            class="select"
                            v-model="form.modifyPwd"
                    >
                        <el-radio
                                :label="item.key"
                                :key="item.key"
                                v-for="item in modifyPwdStatusOptions"
                        >{{item.display_name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="修改杠杆">
                    <el-radio v-model="form.openLeverage" :label="1">允许</el-radio>
                    <el-radio v-model="form.openLeverage" :label="0">不允许</el-radio>
                </el-form-item>
                  <!--美分账户不显示这个 todo-->
                <el-form-item label="交易积分">
                  <el-radio v-model="form.rewardPointFlag" :label="1">统计</el-radio>
                  <el-radio v-model="form.rewardPointFlag" :label="0">不统计</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="saveHandle">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { saveTransUserInfo } from "@/api/ib/tCustMtaccount";
  import checkPermission from "@/utils/permission";
  import { mapGetters } from "vuex";
  export default {
    name: 'mt4EditDialog',
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
      },
      userInfo:{
        type: Object,
        default(){
          return {
            userName: '',
            phone: '',
            emial: '',
          }
        }
      },
      // 美分账户,下拉只显示美分分组,
      // 其他账户, 下拉只显示美元分组
      groupSelf:{
        type: Object,
        default(){
          return {};
        }
      },
    },
    data() {
      return {
        leverList: [],
        form: {
          id: this.userInfo.id,
          mtGroup: '',
          leverage: 1,
          tradeStatus: 0,
          loginStatus: 1,
          sendReport: 1,
          modifyPwd: 1,
          openLeverage: 1,
          rewardPointFlag: 1,
        },
        statusOptions: [
          { key: 0, display_name: "启用" },
          { key: 1, display_name: "停用" }
        ],
        loginStatusOptions: [
          { key: 1, display_name: "启用" },
          { key: 0, display_name: "停用" }
        ],
        dwTypeOptions: [
          { key: 1, display_name: "出金" },
          { key: 2, display_name: "入金" }
        ],
        sendReportStatusOptions: [
          { key: 1, display_name: "是" },
          { key: 0, display_name: "否" }
        ],
        modifyPwdStatusOptions: [
          { key: 1, display_name: "允许" },
          { key: 0, display_name: "不允许" }
        ],
      }
    },
    computed: {
      ...mapGetters(["mtGroups"]),
      groupFilter(){
        if(this.userInfo.currency == 'USD'){
          let filterObject = {};
          for (let key in this.groupSelf) {
            if(key !== 'Cent'){
              filterObject[key] = this.groupSelf[key];
            }
          }
          return filterObject;
        }else {
          let filterObject = {};
          for (let key in this.groupSelf) {
            if(key == 'Cent'){
              filterObject[key] = this.groupSelf[key];
            }
          }
          return filterObject;
        }
      },
    },
    watch:{
      userInfo:{
        handler(newValue){
          this.form.id = newValue.id;
          this.form.mtGroup = newValue.groupDetailId;
          this.form.leverage = newValue.leverage;
          this.form.tradeStatus = newValue.enableReadOnly;
          this.form.loginStatus = newValue.enable;
          this.form.sendReport = newValue.sendReports;
          this.form.modifyPwd = newValue.enableChangePassword;
          this.form.openLeverage = newValue.openLeverage;
          this.form.rewardPointFlag = newValue.rewardPointFlag;
        },
        deep: true
      },
      "form.mtGroup": {
        handler(newVal, oldVal) {
          if(this.mtGroups){
            this.matchLeverageList();
            if(this.leverList.length){
              this.form.leverage = this.leverList[0].lev;
            }
          }
        },
        deep: true
      }
    },
    methods: {
      checkPermission,

      closeDialog(){
        this.$emit('close-dialog');
      },
      beforeHandle(){
        this.closeDialog();
      },
      matchLeverageList(){
        for (let key in this.groupSelf) {
          for (let i = 0; i < this.groupSelf[key].length; i++) {
            if(this.groupSelf[key][i].id == this.form.mtGroup){
              for (let j = 0; j <this.mtGroups.length ; j++) {
                    if(this.mtGroups[j].groupName == this.groupSelf[key][i].groupType){
                      this.setLeverage(this.mtGroups[j].leverageList);
                      break;
                    }
              }
            }
          }
        }
      },
      saveHandle(){
        const params = {
          custId: this.userInfo.id,
          login: this.userInfo.login,
          group: this.form.mtGroup,
          leverage: this.form.leverage,
          enableReadonly: this.form.tradeStatus,
          enable: this.form.loginStatus,
          sendReports: this.form.sendReport ? 1 : 0,
          enableChangePass: this.form.modifyPwd ? 1 : 0,
          openLeverage: this.form.openLeverage,

        };
        // 美分账户不用统计交易积分 todo
        if(!this.userInfo.isCentAccount){
          params.rewardPointFlag = this.form.rewardPointFlag;
        }
        //接口调用
        saveTransUserInfo(params)
          .then(res => {
            this.$notify({
              title: '成功',
              type: "success",
              duration: 2500
            });
            this.$emit('refresh');
          })
          .catch(err => {});
      },
      setLeverage(leverageList) {
        let levList = leverageList.split(",");
        let tempList = [];
        for (let i = 0; i < levList.length; i++) {
          let lev = parseInt(levList[i]);
          let levName = "1:" + levList[i];
          let params = {
            levName: levName,
            lev: lev
          };
          tempList[i] = params;
        }
        this.leverList = tempList;
      },
    }
  }
</script>
<style lang="less" scoped>
    .el-row {
        margin-bottom: 20px;
    }
    .content {
        padding: 0 15px;
    }
</style>
