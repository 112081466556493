<template>
  <div class="head-container">
    <!-- 搜索 -->
     <el-select
       class="filter-item"
       v-model="query.businessType"
       placeholder="账户类型"
       clearable
       @change="toQuery"
       style="width: 100px"
     >
       <el-option
         v-for="(item,index) in businessTypeOptions"
         :key="index"
         :label="item.label"
         :value="item.value"
       ></el-option>
     </el-select>
     <el-select
       class="filter-item"
       v-model="query.tier"
       placeholder="账户类别"
       clearable
       style="width: 120px"
       @change="toQuery"
     >
       <el-option
         v-for="(item,index) in tierOptions"
         :key="index"
         :label="item.label"
         :value="item.value"
       ></el-option>
     </el-select>
    <el-select
        v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_TRADE_INFO'])"
        class="filter-item"
        v-model="query.tag"
        placeholder="账户标签"
        clearable
        style="width: 120px"
        @change="toQuery"
    >
      <el-option
          v-for="(item,index) in tagOption"
          :key="index"
          :label="item.tagName"
          :value="item.id"
      ></el-option>
    </el-select>
    <el-select
        class="filter-item"
        v-model="query.status"
        placeholder="激活状态"
        clearable
        style="width: 120px"
        @change="toQuery"
    >
      <el-option
          v-for="(item,index) in statusOption"
          :key="index"
          :label="item.label"
          :value="item.value"
      ></el-option>
    </el-select>
     <el-input
             v-model="query.mt4Info"
             clearable
             placeholder="MT4分组/账户"
             style="width: 200px;"
             class="filter-item"
             @keyup.enter.native="toQuery"
     />
     <el-input
             v-model="query.custInfo"
             clearable
             placeholder="客户姓名/ID/邮箱"
             style="width: 200px;"
             class="filter-item"
             @keyup.enter.native="toQuery"/>
     <el-input v-model="query.manageInfo" clearable placeholder="客户经理姓名/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
     <el-input
         v-model="query.agentInfo"
         clearable
         placeholder="代理姓名/ID"
         style="width: 150px;"
         class="filter-item"
         @keyup.enter.native="toQuery"
     />
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-upload
          v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT'])"
              ref="upload"
              class="upload-demo"
              :action="filesUploadApiSelf"
              :on-success="handleSuccess"
              :headers="headers"
              :limit=1
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
      >
          <el-button
                  class="filter-item"
                  size="mini"
                  type="primary"
                  icon="el-icon-upload2"
          >批量修改配置</el-button>
      </el-upload>
    <a
        v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT'])"
        :href="downLink" download>
      <el-button
          class="filter-item"
          size="mini"
          type="primary"
          icon="el-icon-download"
      >下载模板</el-button>
    </a>
<!--    todo 权限-->
    <el-button
        v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="mt4OpenHandle"
    >MT4开户</el-button>
  </div>
</template>

<script>
  import { getAccountTagList } from "@/api/ib/tCustMtaccount";
  import { mapGetters } from "vuex"
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      queryMerOptions: null,
      tierOptions:[
        {
          label: '信号账户',
          value: 1,
        },
        {
          label: '投资账户',
          value: 2
        },
      ],
      businessTypeOptions:[
        {
          label: '全部账户(剔除模拟、体验)',
          value: -1,
        },
        {
          label: '普通账户',
          value: 0
        },
        {
          label: 'Social',
          value: 1,
        },
        {
          label: 'MAM账户',
          value: 2
        },
        {
          label: '钱包账户',
          value: 3
        },
        {
          label: '模拟账户',
          value: 5
        },
        {
          label: '体验账户',
          value: 6
        }
      ],
      filesUploadApiSelf: process.env.VUE_APP_API + 'crm/batchModifyMtAccounts',
      downLink: 'https://maxain-prod.s3.ap-southeast-1.amazonaws.com/files/d9f95d992b5740dabdec2d04c6107e92.xlsx',
      tagOption: [],
      statusOption:[
        {label: '激活', value: 1},
        {label: '禁用', value: 0},
      ]
    }
  },
  created(){
    if(checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_TRADE_INFO'])){
      this.getTagInfo();
    }
  },
  computed: {
    ...mapGetters(['filesUploadApi']),
  },
  methods: {
    checkPermission,
    getTagInfo(){
      getAccountTagList().then((res) => {
        this.tagOption = res;
      })
    },
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear');
    },
    beforeAvatarUpload(file) {
      // const isHtml = file.type === 'text/xlsx';
      // if (!isHtml) {
      //   this.$message.error('请上传html文件');
      // }
      return true;
    },
    handleSuccess(response){
      if(response.status != 10000){
        this.$notify.error({
          title: response.message,
          duration: 2500
        });
      }else {
        this.$notify({
          title: '上传成功',
          type: 'success',
          duration: 2500
        });
      }

      this.$refs.upload.clearFiles();
    },
    mt4OpenHandle(){
      this.$emit('openMT4');
    }
  }
}
</script>
<style lang="less">
    .upload-demo {
        display: inline-block;
    }
</style>
